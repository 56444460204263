"use strict";

import Cookies from "js-cookie";
// MOBILE MENU

// MENU SELCTORS

const mobileMenuIcon = document.querySelector(".mobile-bars");
const mobileMenu = document.querySelector(".mobile-menu");
const closeMobileMenu = document.querySelector("#icon-close-menu");

const navElement = document.querySelector("nav");
const navBar = navElement.style.height;
const logo = document.querySelector(".logo");
// MOBILE MENU

let menuToggle = false;

mobileMenuIcon.addEventListener("click", function () {
  if (menuToggle === false) {
    mobileMenu.style.display = "block";
    menuToggle = true;
  } else {
    mobileMenu.style.display = "none";
    menuToggle = false;
  }
});

closeMobileMenu.addEventListener("click", function () {
  mobileMenu.style.display = "none";
  menuToggle = false;
});

// Click logo to go home

logo.addEventListener("click", function () {
  window.location.href = "https://www.bergsjodack.se";
});

// FADE IN SECTIONS

const sectionDivs = document.querySelectorAll(".section-div");

const options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.5,
};

const revealDiv = function (entries, observer) {
  //remove displace and hide class on section div
  const [entry] = entries;

  if (!entry.isIntersecting) return;

  entry.target.classList.remove("hide-displace");
  observer.unobserve(entry.target);
};

const observer = new IntersectionObserver(revealDiv, options);

sectionDivs.forEach(function (div) {
  observer.observe(div);
  div.classList.add("hide-displace");
});

// RECPATCHA

if (document.getElementById("token")) {
  grecaptcha.ready(function () {
    grecaptcha
      .execute("6Lfqk4kdAAAAAEIf_rACtxnt2onp57BABYF8rZj5", {
        action: "homepage",
      })
      .then(function (token) {
        // console.log(token);
        document.getElementById("token").value = token;
      });
    // refresh token every minute to prevent expiration
    setInterval(function () {
      grecaptcha
        .execute("6Lfqk4kdAAAAAEIf_rACtxnt2onp57BABYF8rZj5", {
          action: "homepage",
        })
        .then(function (token) {
          document.getElementById("token").value = token;
        });
    }, 60000);
  });
}

// COOKIES

const header = document.querySelector("header");

const cookieHTML =
  '<div class="cookies"><span>Vi använder cookies för analys och funktioner på sidan. Vi utgår från att det är okej om du stannar kvar på sidan.</span><button class="btn-cookies">Okej!</button></div>';

window.addEventListener("load", function () {
  if (!Cookies.get("consent")) {
    header.insertAdjacentHTML("afterend", cookieHTML);
    const cookieBtn = document.querySelector(".btn-cookies");
    const cookieDiv = document.querySelector(".cookies");
    cookieBtn.addEventListener("click", function () {
      cookieDiv.classList.add("hidden");
      Cookies.set("consent", "true", { expires: 1 });
    });
  }
});

// ALERT

const alertWindow = document.querySelector(".alert-warning");

alertWindow?.addEventListener("click", function () {
  alertWindow.classList.add("hidden");
});
